import { IModal } from '@/components';
import Modal from '@/components/modal.vue';
import { ITable } from '@/components/table';
import Table from '@/components/table/table.vue';
import router from '@/router';
import store, { IState } from '@/store';
import { IStateDeviceCategory } from '@/store/modules/deviceCategories';
import { IDeviceCategoriesTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { table } from './DeviceCategories.data';

@Component({
  name: 'DeviceCategories',
  components: { Table, Modal }
})
class DeviceCategories extends Vue {
  @Action('fetchDeviceCategories') public fetch!: () => void;

  @Getter('getDeviceCategories') public deviceCategories!: IStateDeviceCategory[];

  @Getter('getDeviceCategoriesTranslations') public translations!: IDeviceCategoriesTranslations;

  @Mutation('selectDeviceCategory') public select!: (deviceCategory: IStateDeviceCategory) => void;
  public table = {} as ITable<IStateDeviceCategory>;
  public modal: IModal = {
    isActive: true,
    isLarge: true,
    close: () => {
      router.push('/devices');
    }
  };
  public created() {

    this.fetch();

    this.table = table(this.translations);
    this.table.onSelectedItem = this.select;

    store.subscribe((mutation: MutationPayload, state: IState) => {
      if (mutation.type !== 'selectDeviceCategory') {

        if (mutation.type === 'setLanguage') {

          this.table = { ...this.table, ...table(this.translations) };

        }

        const key = Object.keys(this.table.order) as Array<Extract<IStateDeviceCategory, string>>;
        const field = key[0];
        const order = this.table.order[key[0]];

        this.table = {
          ...this.table,
          data: sort(field, order, state.deviceCategories.list)
        };

      }

    });

  }
  public close() {
    router.push('/devices/models');
  }
}

export default DeviceCategories;
